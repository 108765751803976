$(document).ready(function () {
	
    $("img.has-tooltip").tooltip({
        title: function () {
            return $(this).attr("alt");
        },
        placement: "auto"
    });

    $("a.has-tooltip").tooltip({
        placement: "auto"
	});

	$(".booking-form").find("div.col-sm-offset-6").removeClass('col-sm-offset-6');

	function fixHeights() {
		$(".RotatorTestimonialContent").sameHeight();
	}

	setTimeout(fixHeights, 500);
	
	$(window).on("resize", function () {
		setTimeout(fixHeights, 500);
	});
});

